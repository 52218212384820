<template lang="">
  <div>
    <!-- 列表搜索 -->
    <el-form :inline="true" :model="pages" class="demo-form-inline">
      <el-form-item label="计划年份">
        <el-date-picker
          v-model="pages.year"
          type="year"
          placeholder="选择年"
          value-format="yyyy"
          :picker-options="limitGetTime"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="所属项目部">
        <el-select v-model="pages.ids" placeholder="请选择所属项目部" clearable multiple collapse-tags>
          <el-option v-for="item in orgList" :key="item.orgId" :label="item.orgName" :value="item.orgId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="btn">
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button @click="resetInput">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- 功能区按钮 -->
    <div>
      <el-button v-if="$check('env-funds-use-add')" type="primary" @click="addInfo" icon="el-icon-plus">新增经费使用记录</el-button>
      <el-button
        v-if="$check('env-funds-use-export')"
        class="exportbtn"
        :disabled="!isChoose"
        v-loading.fullscreen="exportLoading"
        element-loading-text="拼命导出中"
        element-loading-background="rgba(0, 0, 0, 0.1)"
        @click="exportData"
        >导出</el-button
      >
    </div>
    <!-- 显示列表 -->
    <el-table
      ref="multipleTable"
      :data="tableData"
      stripe
      style="width: 100%"
      v-loading="tabelLoading"
      @selection-change="handleSelectionChange"
      max-height="550"
    >
      <el-table-column type="selection" width="55" align="center" fixed> </el-table-column>
      <el-table-column label="序号" align="center" width="55" fixed>
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="所属项目部" prop="orgName" align="center" width="150" show-overflow-tooltip sortable>
      </el-table-column>
      <el-table-column label="使用年份" prop="year" align="center" show-overflow-tooltip sortable> </el-table-column>
      <el-table-column label="使用金额（元）" prop="planMoney" align="center" show-overflow-tooltip sortable>
      </el-table-column>
      <el-table-column label="操作" align="center" width="150" fixed="right">
        <template slot-scope="scope">
          <span v-if="$check('env-funds-use-edit')" class="action-btn" @click="handleDetails(scope.row)">查看详情</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pages.pageNo"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pages.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!-- 详情、新增、修改框 -->
    <listDialog
      ref="formRef"
      :dialog-title.sync="dialogTitle"
      :dialog-visible.sync="dialogVisible"
      @handel-success="handelSuccess"
      :orgList="orgList"
      :type="'2'"
    ></listDialog>
  </div>
</template>
<script>
import { getList,exportRecord} from '@/api/general-user/env/funds';
import { getDataRangByNature } from '@/api/general-user/env/common';
import listDialog from './listDialog.vue';
import { cloneDeep } from 'lodash';
import { exportFun } from '@/utils/export.js';
export default {
  components: { listDialog },
  data() {
    return {
      /* 分页查询 */
      pages: {
        year: '',
        ids: [],
        pageNo: 1,
        pageSize: 10,
        type:"2"
      },
      total: 0,
      /* 机构列表 */
      orgList: [],
      limitGetTime: {
        //选择前8年
        disabledDate(time) {
          return time.getTime() < Date.parse(new Date().getFullYear() - 8) || time.getTime() > Date.now();
        }
      },
      /* 导出按钮状态 */
      isChoose: false,
      exportLoading: false,
      /* 导出数据ID */
      ids: [],
      /* 表格 */
      tabelLoading: false,
      tableData: [],
      /* 弹出框回显 */
      dialogVisible: false,
      dialogTitle: ''
    };
  },
  methods: {
    /* 搜索 */
    onSubmit() {
      this.pages.pageNo = 1;
      this.pages.pageSize = 10;
      this.getList();
    },
    /* 重置 */
    resetInput() {
      this.pages.year = '';
      this.pages.ids = [];
      this.pages.pageNo = 1;
      this.pages.pageSize = 10;
      this.$refs.multipleTable.clearSort();
      this.getList();
    },
    /* 列表 */
    getList() {
      this.tabelLoading = true;
      getList(this.pages)
        .then(result => {
            this.tableData = result.data.records;
            this.total = result.data.total;
            this.tabelLoading = false;
        })
        .catch(err => {
          this.$message.error(err);
        });
    },
    /* 新增 */
    addInfo() {
      this.dialogTitle = '新增记录';
      this.dialogVisible = true;
      this.$refs.formRef.isRead = false;
      const obj = { 
        epFundingDetailParams: [], 
        year: new Date().getFullYear().toString() ,
        type:"2"
        };
      for (let i = 0; i < 12; i++) {
        obj.epFundingDetailParams.push({ time: (i + 1).toString() }); //填充月份，撑开数据12行(无法确定选择年份，但要显示表格。通过接口组合)
      }
      this.$refs.formRef.initFormDetail(obj); //调用子组件的方法时使表单回显
    },
    /* 导出数据 */
    exportData() {
      this.exportLoading = true;
      exportRecord({ ids:this.ids,type:"2"})
      .then(res => {
          exportFun(res);
          this.exportLoading = false;
          this.$message({
            type: 'success',
            message: '下载数据成功',
          })
          this.$refs.multipleTable.clearSelection();
        }).catch(() => {
            this.exportLoading = false;
            this.$refs.multipleTable.clearSelection();
          })
    },
    /* 勾选框获取导出ID */
    handleSelectionChange(row) {
       this.ids = [];
      row.forEach(item => {
        this.ids.push(item.id);
      });
      this.isChoose = Boolean(this.ids.length);
    },
    /* 查看详情 */
    handleDetails(val) {
      this.dialogTitle = '查看详情';
      this.$refs.formRef.isRead = true;
      this.dialogVisible = true;
      const obj = cloneDeep(val);
      obj.type="2";
      this.$refs.formRef.initFormDetail(obj); //调用子组件的方法时使表单回显
    },
    /* 分页大小 */
    handleSizeChange(val) {
      this.pages.pageNo = 1; //重置为第一页
      this.pages.pageSize = val;
      this.getList();
    },
    /* 分页当前页 */
    handleCurrentChange(val) {
      this.pages.pageNo = val;
      this.getList();
    },
    /* 获取所属项目部 */
    getDataRangByNature() {
      getDataRangByNature()
        .then(result => {
            this.orgList = result.data;
        })
        .catch(err => {
          this.$message.error(err);
        });
    },
    /* 弹窗操作回调 */
    handelSuccess(arr) {
      //arr[0]true为返回列表第一页，false为编辑，在当前页
      //arr[1]true否 false为是 删除操作
      if (arr[0]) {
        this.pages.pageNo = 1;
      }
      if (arr[1]) {
        if (this.tableData.length === 1 && this.pages.pageNo > 1) {
          this.pages.pageNo = this.pages.pageNo - 1; //删除一页的最后一条时，跳转前一页（大于1页）
        }
      }
      this.getList();
      //刷新列表
    }
  },
  mounted() {
    this.getList();
    this.getDataRangByNature();
  }
};
</script>
<style lang="less" scoped>
.exportbtn {
  float: right;
}
</style>
