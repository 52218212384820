<template>
  <div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="show"
      :close-on-click-modal="false"
      @close="handelClose"
      width="960px"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        v-loading="dialogLoading"
        class="form-box"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属项目部" prop="projectDepartment" :required="!isRead">
              <div class="block">
                <el-select v-model="ruleForm.projectDepartment">
                  <el-option
                    v-for="item in orgList"
                    :key="item.orgId"
                    :label="item.orgName"
                    :value="item.orgId"
                  ></el-option>
                </el-select>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="使用年份" prop="year">
              <el-date-picker
                v-model="ruleForm.year"
                type="year"
                placeholder="选择使用年份"
                value-format="yyyy"
                :picker-options="limitGetTime"
                :clearable="false"
                :editable="false"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-table 
          ref="multipleTable" 
          :data="ruleForm.epFundingDetailParams" 
          border
          style="width: 100%"
          class="tableinput"
          >
            <el-table-column label="序号" align="center" width="55" fixed>
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column label="使用时间" prop="time" align="center" width="100">
              <template slot-scope="scope">
                {{scope.row.time}}
              </template>
            </el-table-column>
            <el-table-column
              label="使用费用（元）"
              prop="money"
              align="center"
              show-overflow-tooltip
            >
            <template slot-scope="scope">
                <el-input 
                v-model="scope.row.money" 
                :disabled="isRead"
                oninput="value=value.replace(/[^\d]/g,'')"
                ></el-input>
            </template>
            </el-input>
            </el-table-column>
            <el-table-column
              label="使用类型"
              prop="type"
              align="center"
              :maxlength='50'
            >
            <template slot-scope="scope">
              <el-input 
              v-model="scope.row.type" 
              :disabled="isRead"
              ></el-input>
            </template>
            </el-table-column>
            <el-table-column
              label="备注"
              prop="remark"
              align="center"
            >
            <template slot-scope="scope">
              <el-input 
              v-model="scope.row.remark" 
              :disabled="isRead"
              :maxlength='50'
              ></el-input>
            </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <span v-if="isRead">
          <el-button type="danger" @click="deleteInfo" :loading="btnLoading">删 除</el-button>
          <el-button type="primary" @click="updataInfo">修 改</el-button>
        </span>
        <span v-else>
          <el-button @click="handelchanel">取 消</el-button>
          <el-button type="primary" @click="handelsure" :loading="btnLoading">确 定</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { deleteRecord, addOrEditRecord, checkPlanOrUse } from '@/api/general-user/env/funds';
import { validateRules } from './validateRules.js';

export default {
  mixins: [validateRules],
  props: {
    dialogTitle: {
      //标题
      type: String,
      required: true,
      default: ''
    },
    dialogVisible: {
      //显隐
      type: Boolean,
      required: true,
      default: false
    },
    /* 项目部 */
    orgList: {
      type: Array,
      required: true,
      default: []
    },
    /* 接口type */
    type:{
      type:String,
      require:true,
      dafault:"2"//1计划，2使用
    }
  },
  data() {
    return {
      /* 显隐 */
      show: this.dialogVisible,
      innerVisible:false,
      /* 显示数据 */
      ruleForm: {},
      /* 是否是查看详情 */
      isRead: false,
      /* 确认按钮状态 */
      btnLoading: false,
      /* 自身机构 */
      selfOrg: {},
      dialogLoading: false,
      /* 时间选取今天以前 */
      limitGetTime: {
        //选择前8年
        disabledDate(time) {
          return time.getTime() < Date.parse(new Date().getFullYear() - 4) || time.getTime() > Date.now()&&time.getTime() < Date.now()||time.getTime() > Date.parse(new Date().getFullYear() + 4);
        }
      },
      id: '',
      sysFileInfos: []
    };
  },
  methods: {
    /* 父级传递的值 */
    initFormDetail(val) {
      this.resetRuleform();
      this.ruleForm = val;
      this.id = val.id; //该数据ID
    },
    /* 退出重置 */
    handelClose() {
      this.resetRuleform();
      this.$refs.ruleForm.resetFields();
      this.handelchanel();
    },
    /* 关闭弹出框 */
    handelchanel() {
      this.$emit('update:dialogVisible', false);
    },
    /* 新增或修改 */
    handelsure() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
            // this.totalMoney();
            this.checkPlanOrUse();
        } else {
          return false;
        }
      });
    },
    /* 新增方法 */
    addRecord() {
      this.btnLoading = true;
      let {id,orgName,planMoney,...fartherForm}=this.ruleForm;
      fartherForm.epFundingDetailParams.map((item)=>{
        delete item.id;
        delete item.isDelete;
        delete item.ledgerId;
        delete item.type;
      })
      fartherForm.type=this.type;
      addOrEditRecord(fartherForm)
        .then(res => {
          if (res.code === 200) {
            this.btnLoading = false;
            this.$message.success('新增成功');
            this.$emit('update:dialogVisible', false);
            this.$emit('handel-success', [true, false]);
          }
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    /* 修改接口调用*/
    EditRecord() {
      this.btnLoading = true;
      let {id,orgName,planMoney,...fartherForm}=this.ruleForm;
      fartherForm.epFundingDetailParams.map((item)=>{
        delete item.id;
        delete item.isDelete;
        delete item.ledgerId;
        delete item.type;//待定
      })
      fartherForm.type=this.type;
      addOrEditRecord(fartherForm)
        .then(res => {
          if (res.code === 200) {
            this.btnLoading = false;
            this.$message.success('修改成功');
            this.$emit('update:dialogVisible', false);
            this.$emit('handel-success', [false, false]);
          }
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    deleteInfo() {
      this.$confirm('请确认是否删除该记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.deleteRecord();
      });
    },
    /* 删除 */
    deleteRecord() {
      deleteRecord({ id: this.id }).then(res => {
        if (res.code === 200) {
          this.$message.success('删除成功');
          this.$emit('update:dialogVisible', false);
          this.$emit('handel-success', [false, true]);
        }
      });
    },
    /* 修改 */
    updataInfo() {
      this.dialogLoading = true;
      setTimeout(() => {
        this.dialogLoading = false;
      }, 500);
      this.$emit('update:dialogTitle', '修改记录');
      this.isRead = false;
    },
    /* 检查是否已有记录 */
    checkPlanOrUse(){
      this.btnLoading=true;
      let {epFundingDetailParams,id,planMoney,orgName,...form}=this.ruleForm;
      form.type=this.type;
      checkPlanOrUse(form).then((result) => {
        if(result.code===200){
          if(!result.data){
            this.addRecord();
          }else{
              this.$confirm('该项目部已存在本年度环保经费使用记录，请确认是否覆盖该记录？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              closeOnClickModal: false,
              type: 'warning'
            }).then(() => {
              this.EditRecord();
            });
          }
        this.btnLoading=false;
        }
      }).catch((err) => {
      this.btnLoading=false;
      });
    },
    /* 重置方法 带参数则剔除多余的字段 */
    resetRuleform(val) {
      this.ruleForm = {};
    },
    /* 统计合计金额 */
    /* totalMoney(){
      this.ruleForm.planMoney=0n;//带n,BigInt类型
      this.ruleForm.epFundingDetailParams.map((item)=>{
       if(item.money){
         this.ruleForm.planMoney+=BigInt(item.money);
       }
      });
      this.ruleForm.planMoney=this.ruleForm.planMoney.toString();
    } */
  },
  created() {},
  watch: {
    dialogVisible(val) {
      this.show = val;
    },
    isRead(val) {
      this.isRead = val;
    },
    'ruleForm.year'(val){
      if(val){
        this.ruleForm.epFundingDetailParams.forEach((item,index) => {
            item.time=val+"年"+(index+1)+"月";
          });
      }
    }
  }
};
</script>
